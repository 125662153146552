package pages

import react.FC
import react.Props
import react.dom.html.ReactHTML.p

val Home = FC<Props> {
    p {
        +"Hello"
    }
    p {
        +"Under construction"
    }
}
