package pages

import react.FC
import react.Props
import react.dom.html.ReactHTML.p

val About = FC<Props> {
    p {
        +"TODO"
    }
}
